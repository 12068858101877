import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';

import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import 'audio-react-recorder/dist/index.css'
import axios from 'axios'
import JsPDF from 'jspdf'


class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      recordState: null,
      audioData: null,
      voiceData: null,
      selectedFile: null,
      disabled: false,
      disableFileUpload: false,
      responseMessage: null
    }
  }


  start = () => {
    this.setState({
      recordState: RecordState.START
    })
  }

  pause = () => {
    this.setState({
      recordState: RecordState.PAUSE
    })
  }

  stop = () => {
    this.setState({
      recordState: RecordState.STOP
    })
  }

  onStop = (data) => {
    this.setState({
      audioData: data
    })
    console.log('onStop: audio data', data)
    this.handleUpload(data)
  }

  handleUpload = async (data) => {
    const formData = new FormData()
    formData.append('file', data.blob, 'sample.wav')
    console.log(formData)
    const response = await axios({
      method: 'post',
      url: 'https://voiceapis.vplaysmart.com/file-upload',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    console.log(response)
  }

  changeHandler = (event) => {
    //console.log(event.target.files)
    this.setState({
      selectedFile: event.target.files[0]
    })
  }

  handleSubmit = async () => {
    this.setState({ disableFileUpload: true })
    this.setState({ disabled: true })
    const formData = new FormData()
    console.log('selected file::', this.state.selectedFile)
    formData.append('file', this.state.selectedFile)
    console.log(formData)
    console.log(this.state.selectedFile)
    try {
      const response = await axios({
        method: 'post',
        url: 'https://voiceapis.vplaysmart.com/file-upload',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      console.log(response)
      this.setState({ responseMessage: response.data.message })
      if (response.status) {
        try {
          const response = await fetch(
            'https://voiceapis.vplaysmart.com/getvoicedatanew',
            {
              method: 'GET',
              headers: {
                Accept: 'application/json'
              }
            }
          )

          if (!response.ok) {
            throw new Error(`Error! status: ${response.status}`)
          }
          const result = await response.json()
          console.log(result)

          this.setState({ voiceData: result })
          this.setState({ disabled: false })
          this.setState({ disableFileUpload: false })
          if(result) {
            const searchParams = new URLSearchParams(window.location.search);
            const userId = searchParams.get('user_id');
            const data = {"user_id": userId, "analysis_data": result}
            const response_s = await axios({
              method: 'post',
              url: 'https://vplaysmart.com/wp-json/event-manager/v1/va_user_ins',
              data: data,
              headers: { 'Content-Type': 'application/json' }
            })

            console.log(response_s);
            if(response_s) {
              console.log("Inserted data");
            } else {
              console.log("failed to insert");
            }
          }
        } catch (err) {
          console.log(err)
        }
      }
    } catch (error) {
      this.setState({ responseMessage: error.response.data.message })
      this.setState({ disableFileUpload: false })
      console.log(error)
    }
  }

  handleClick = async () => {
    this.setState({ disabled: true })
    try {
      const response = await fetch(
        'https://voiceapis.vplaysmart.com/getvoicedatanew',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json'
          }
        }
      )

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`)
      }
      const result = await response.json()
      console.log(result)

      if(result) {
        const searchParams = new URLSearchParams(window.location.search);
        const userId = searchParams.get('user_id');
        const data = {"user_id": userId, "analysis_data": result}
        const response_s = await axios({
          method: 'post',
          url: 'https://vplaysmart.com/wp-json/event-manager/v1/va_user_ins',
          data: data,
          headers: { 'Content-Type': 'application/json' }
        })

        console.log(response_s);
        if(response_s) {
          console.log("Inserted data");
        } else {
          console.log("failed to insert");
        }
      }
      //this.setState({voiceData: result})
      this.setState({ voiceData: result })
      this.setState({ disabled: false })
    } catch (err) {
      console.log(err)
    }
    //const data = {"Pronunciation_probability_score_percentage":100,"articulation_rate":5,"balance":0.8,"f0_mean":"150.62 Hz","gender":"a Male, mood of speech: speaking passionately","number_of_pauses":8,"number_of_syllables":127,"original_duration":"32.8 sec","rate_of_speech":4,"speaking_duration":"26.8 sec"}

    //alert("button clicked")
    console.log('button clicked', this.state.voiceData)
  }

  generatePDF = () => {
    const report = new JsPDF({
      orientation: 'p',
      format: 'a4',
      unit: 'px',
      hotfixes: ['px_scaling']
    })
    report
      .html(document.querySelector('#main-voice-row'), {
        callback(doc) {
          doc.output('dataurlnewwindow')
        },
        x: 0,
        y: 0,
        autoPaging: 'text',
        margin: 20,
        width: 800,
        windowWidth: 900
      })
      .then(() => {
        report.save('rehearsel_report.pdf')
      })
  }

  sendMail = () => {
    alert('mail sent')
  }

  render() {
    const { recordState } = this.state
    const splitContent = (data) => {
      return data.split(':')
    }
    return (
      <div id='main-root'>
        <div id='header-root'>Recording and Voice Analysis</div>
        <AudioReactRecorder
          state={recordState}
          onStop={this.onStop}
          backgroundColor='rgb(255,255,255)'
        />
        <div id='full-sections-div'>
          <div className='main-record-div' id='main-record-div'>
            <div className='record-section'>
              <div>
                <div>Record and Analyze</div>
                <audio
                  id='audio'
                  controls
                  src={this.state.audioData ? this.state.audioData.url : null}
                ></audio>
              </div>
              <div id='record-buttons'>
                <button id='record' onClick={this.start}>
                  Start
                </button>
                &nbsp;&nbsp;
                <button id='pause' onClick={this.pause}>
                  Pause
                </button>
                &nbsp;
                <button id='stop' onClick={this.stop}>
                  Stop
                </button>
              </div>
              {/*<div id="label-section"><b>Note:</b> After recording please click on 3 dots next to audio to download the recorded audio</div>*/}
              <div className='input-analyze-button'>
                <button
                  id='analyze-button'
                  onClick={this.handleClick.bind(this)}
                  disabled={this.state.disabled}
                >
                  Analyze Recorded Data
                </button>
              </div>
              {/*<div className="input-button-submit"><button id="submit" onClick={this.handleSubmit} disabled={this.state.disableFileUpload}>Analyze</button></div>*/}
            </div>

            <div className='upload-component'>
              {this.state.responseMessage && (
                <div id='responseMsg'>
                  <div className='resMsg'>{this.state.responseMessage}</div>
                </div>
              )}
              <div className='upload-recorded-voice'>
                Upload & Analyze Recorded Voice
              </div>
              <div className='input-file-section'>
                <input type='file' name='file' onChange={this.changeHandler} />
              </div>
              <div className='input-button-submit'>
                <button
                  id='submit'
                  onClick={this.handleSubmit}
                  disabled={this.state.disableFileUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          {/*<div className="container"><div className="analyze">Analyze</div>
          <div className="input-analyze-button"><button id="analyze-button" onClick = {this.handleClick.bind(this)} disabled={this.state.disabled}>Analyze Voice Data</button></div>
        </div>*/}
          {this.state.disabled && (
            <div id='cover-spin'>Voice Analysis is in progress...</div>
          )}
          {this.state.voiceData && (
            <div id='main-voice-row' className='main-voice-row'>
              {/*<div className="inner-row-data">
            <div className="inner-row-data-label"><b>Metrics</b></div>
            <div className="inner-row-data-value"><b>Your Results</b></div>
            <div className="inner-row-data-desc"><b>Description</b></div>
          </div>*/}
           <div className='reharsal-div'>
                <div className='fullwidth'>Your Rehearsel Report</div>
                <div className='export-pdf'>
                  <button
                    id='button-value'
                    onClick={this.generatePDF}
                    type='button'
                  >
                    Export PDF
                  </button>
                </div>
                <div>&nbsp;</div>
                <div className='send-email'>
                  <button
                    id='button-value'
                    onClick={this.sendMail}
                    type='button'
                  >
                    Send Email
                  </button>
                </div>
              </div>
			  <div className='inner-row-data-label-1'>Summary</div>
			  <div class="new-summary-design">
				<div className='inner-row-data'>
					<div className='inner-row-data-label'>{this.state.voiceData && splitContent(this.state.voiceData.feedback_data[0])[0]}</div>
					<div className='inner-row-data-value'>
					  {this.state.voiceData && splitContent(this.state.voiceData.feedback_data[0])[1]}
					</div>
					<div className="inner-row-data-desc">{this.state.voiceData && this.state.voiceData.feedback_data[1]}</div>
				</div>
				<div className='inner-row-data'>
					<div className='inner-row-data-label'>{this.state.voiceData && splitContent(this.state.voiceData.feedback_data[2])[0]}</div>
					<div className='inner-row-data-value'>
					  {this.state.voiceData && splitContent(this.state.voiceData.feedback_data[2])[1]}
					</div>
					<div className="inner-row-data-desc">{this.state.voiceData && this.state.voiceData.feedback_data[3]}</div>
				</div>
				<div className='inner-row-data'>
					<div className='inner-row-data-label'>{this.state.voiceData && splitContent(this.state.voiceData.feedback_data[4])[0]}</div>
					<div className='inner-row-data-value'>
					  {this.state.voiceData && splitContent(this.state.voiceData.feedback_data[4])[1]}
					</div>
					<div className="inner-row-data-desc">{this.state.voiceData && this.state.voiceData.feedback_data[5]}</div>
				</div>
				<div className='inner-row-data'>
					<div className='inner-row-data-label'>{this.state.voiceData && splitContent(this.state.voiceData.feedback_data[6])[0]}</div>
					<div className='inner-row-data-value'>
					  {this.state.voiceData && splitContent(this.state.voiceData.feedback_data[6])[1]}
					</div>
					<div className="inner-row-data-desc">{this.state.voiceData && this.state.voiceData.feedback_data[7]}</div>
				</div>
				<div className='inner-row-data'>
					<div className='inner-row-data-label'>{this.state.voiceData && splitContent(this.state.voiceData.feedback_data[8])[0]}</div>
					<div className='inner-row-data-value'>
					  {this.state.voiceData && splitContent(this.state.voiceData.feedback_data[8])[1]}
					</div>
					<div className="inner-row-data-desc">{this.state.voiceData && this.state.voiceData.feedback_data[9]}</div>
				</div>
				<div className='inner-row-data'>
					<div className='inner-row-data-label'>{this.state.voiceData && splitContent(this.state.voiceData.feedback_data[10])[0]}</div>
					<div className='inner-row-data-value'>
					  {this.state.voiceData && splitContent(this.state.voiceData.feedback_data[10])[1]}
					</div>
					<div className="inner-row-data-desc">{this.state.voiceData && this.state.voiceData.feedback_data[11]}</div>
				</div>
				<div className='inner-row-data'>
					<div className='inner-row-data-label'>{this.state.voiceData && splitContent(this.state.voiceData.feedback_data[12])[0]}</div>
					<div className='inner-row-data-value'>
					  {this.state.voiceData && splitContent(this.state.voiceData.feedback_data[12])[1]}
					</div>
					<div className="inner-row-data-desc">{this.state.voiceData && this.state.voiceData.feedback_data[13]}</div>
				</div>
				<div className='inner-row-data'>
					<div className='inner-row-data-label'>{this.state.voiceData && splitContent(this.state.voiceData.feedback_data[14])[0]}</div>
					<div className='inner-row-data-value'>
					  {this.state.voiceData && splitContent(this.state.voiceData.feedback_data[14])[1]}
					</div>
					<div className="inner-row-data-desc">{this.state.voiceData && this.state.voiceData.feedback_data[15]}</div>
				</div>
				<div className='inner-row-data'>
					<div className='inner-row-data-label'>{this.state.voiceData && splitContent(this.state.voiceData.feedback_data[8])[0]}</div>
					<div className='inner-row-data-value'>
					  {this.state.voiceData && splitContent(this.state.voiceData.feedback_data[8])[1]}
					</div>
					<div className="inner-row-data-desc">{this.state.voiceData && this.state.voiceData.feedback_data[9]}</div>
				</div>
			  </div>
              {/*<div className='inner-row-data-full'>
                <div className='inner-row-data-label-1'>Summary</div>
                <div className='inner-row-data-full-1'>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[0])[0]}
                  </div>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[0])[1]}
                    <br />
                    <br />
                    {this.state.voiceData &&
                      this.state.voiceData.feedback_data[1]}
                  </div>
                </div>
				
                <div className='inner-row-data-full-1'>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[2])[0]}
                  </div>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[2])[1]}
                    <br />
                    <br />
                    {this.state.voiceData &&
                      this.state.voiceData.feedback_data[3]}
                  </div>
                </div>
                <div className='inner-row-data-full-1'>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[4])[0]}
                  </div>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[4])[1]}
                    <br />
                    <br />
                    {this.state.voiceData &&
                      this.state.voiceData.feedback_data[5]}
                  </div>
                </div>
                <div className='inner-row-data-full-1'>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[6])[0]}
                  </div>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[6])[1]}
                    <br />
                    <br />
                    {this.state.voiceData &&
                      this.state.voiceData.feedback_data[7]}
                  </div>
                </div>
                <div className='inner-row-data-full-1'>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[8])[0]}
                  </div>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[8])[1]}
                    <br />
                    <br />
                    {this.state.voiceData &&
                      this.state.voiceData.feedback_data[9]}
                  </div>
                </div>
                <div className='inner-row-data-full-1'>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[10])[0]}
                  </div>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[10])[1]}
                    <br />
                    <br />
                    {this.state.voiceData &&
                      this.state.voiceData.feedback_data[11]}
                  </div>
                </div>
                <div className='inner-row-data-full-1'>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[12])[0]}
                  </div>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[12])[1]}
                    <br />
                    <br />
                    {this.state.voiceData &&
                      this.state.voiceData.feedback_data[13]}
                  </div>
                </div>
                <div className='inner-row-data-full-1'>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[14])[0]}
                  </div>
                  <div className='inner-row-data-value-1'>
                    {this.state.voiceData &&
                      splitContent(this.state.voiceData.feedback_data[14])[1]}
                    <br />
                    <br />
                    {this.state.voiceData &&
                      this.state.voiceData.feedback_data[15]}
                  </div>
                </div>
                <div className="inner-row-data-desc">Have you ever heard someone singing a really high note or a really low note in a song? Well, the high or low sound they make is because of something called f0. It's like a little machine in your throat that makes your voice go up and down, like a rollercoaster! Some people have a machine that makes their voice go up really high, while others have a machine that makes their voice go down really low. This is what makes our voices unique and interesting to listen to!</div>
              </div>
              <div className='inner-row-data'>
                <div className='inner-row-data-label'>Rate of speech</div>
                <div className='inner-row-data-value'>
                  {this.state.voiceData && this.state.voiceData.speech_rate}
                </div>
                <div className="inner-row-data-desc">This is like a speedometer for our words - sometimes we go fast like a race car, and sometimes we go slow like a snail.</div>
              </div>
              <div className='inner-row-data'>
                <div className='inner-row-data-label'>Mean Pitch</div>
                <div className='inner-row-data-value'>
                  {this.state.voiceData && this.state.voiceData.mean_pitch}
                </div>
                <div className="inner-row-data-desc">Talking speed is like a race to say as many words as you can in a certain amount of time - the faster you go, the more you can say, but you also have to make sure you say them clearly so people can keep up!</div>
              </div>
              <div className='inner-row-data'>
                <div className='inner-row-data-label'>
                  Pitch Standard Division
                </div>
                <div className='inner-row-data-value'>
                  {this.state.voiceData &&
                    this.state.voiceData.pitch_standard_division}
                </div>
                <div className="inner-row-data-desc">-</div>
              </div>
              <div className='inner-row-data'>
                <div className='inner-row-data-label'>Filler Words </div>
                <div className='inner-row-data-value'>
                  {this.state.voiceData &&
                    JSON.stringify(this.state.voiceData.filler_count)}
                </div>
                <div className="inner-row-data-desc">Fillers words such as um or uh are words, sounds, or phrases people use to "fill in" empty spaces in communication. In speech, they usually indicate the speaker is thinking about what to say next, while in writing they’re often clichés or padding. In most cases, filler words have a negative effect and reflect poorly on your communication skills. But because they come out unconsciously, they can be hard to, you know, stop.</div>
              </div>
              <div className='inner-row-data'>
                <div className='inner-row-data-label'>Disfluencies</div>
                <div className='inner-row-data-value'>
                  {this.state.voiceData && this.state.voiceData.disfluencies}
                </div>
                <div className="inner-row-data-desc">Transcription of the input</div>
              </div>
              <div className='inner-row-data'>
                <div className='inner-row-data-label'>Lexical Diversity</div>
                <div className='inner-row-data-value'>
                  {this.state.voiceData &&
                    this.state.voiceData.lexical_diversity}
                </div>
                <div className="inner-row-data-desc">Balance is like playing a game of tag - we want to make sure that everyone gets a chance to be "it" and that nobody is left out. It's like taking turns on the swings, making sure everyone gets a chance to go up and down. By keeping things balanced, we can make sure everyone is heard and has fun</div>
              </div>
              <div className='inner-row-data'>
                <div className='inner-row-data-label'>Number of pauses</div>
                <div className='inner-row-data-value'>
                  {this.state.voiceData && this.state.voiceData.pauses}
                </div>
                <div className="inner-row-data-desc">Pauses are when we stop talking for a moment, we count how many times we pause to learn how we can speak better</div>
              </div>
              <div className='inner-row-data'>
                <div className='inner-row-data-label'>Sentiment Scores</div>
                <div className='inner-row-data-value'>
                  {this.state.voiceData &&
                    JSON.stringify(this.state.voiceData.sentiment_scores)}
                </div>
                <div className="inner-row-data-desc">The number of syllables is like counting the beats in a song or a poem. It helps us see how clear and easy to understand someone's words are, and how they can improve their speech to make it even better!"</div>
              </div>
              <div className='inner-row-data'>
                <div className='inner-row-data-label'>Transcribed Text</div>
                <div className='inner-row-data-value'>
                  {this.state.voiceData &&
                    this.state.voiceData.transcribed_text}
                </div>
                <div className="inner-row-data-desc">"This is like a stopwatch for talking, and helps us speak at a pace that's just right - not too fast, not too slow - to keep people interested and listening to what we have to say!"</div>
              </div>
              <div className='inner-row-data top-1'>
                <div className='inner-row-data-label'>
                  Pronunciation and accuracy
                </div>
                <div className='inner-row-data-value'>
                  {this.state.voiceData &&
                    this.state.voiceData.pronounce_acuracy_transcription}
                </div>
                <div className="inner-row-data-desc">Think of your speech accuracy percentage like a score in a game - the closer you are to 100, the better you did! It's like getting a perfect score for talking</div>
              </div>
              <div className='inner-row-data'>
                <div className='inner-row-data-label'>
                  Emotional Tone Scores
                </div>
                <div className='inner-row-data-value'>
                  {this.state.voiceData &&
                    JSON.stringify(this.state.voiceData.emotional_tone_scores)}
                </div>
                <div className="inner-row-data-desc">Speaking duration is about measuring how much someone talks during a conversation or presentation to learn more about how well they communicate.</div>
              </div>*/}
			  </div>
          )}
          {this.state.voiceData && (
            <div id='button-export'>
              <button
                id='button-value'
                onClick={this.generatePDF}
                type='button'
              >
                Export PDF
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default App
